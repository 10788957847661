import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import ItemTypes from "./ItemTypes";

const style = {
  border: "1px dashed gray",
  padding: "0.5rem 1rem",
  marginBottom: ".5rem",
  backgroundColor: "white",
  cursor: "pointer",
};

export default function (ComposedComponent) {
  function Card({ seq = -1, ...props }) {
    const cardRef = useRef(null);
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.CARD,
      item: { id: props.id, index: props.index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });


    const [, drop] = useDrop({
      accept: [ItemTypes.CARD, ItemTypes.BOX],
      drop: (item) => {
        if (!cardRef.current) {
          return;
        }

        const hoverIndex = props.index;
        const dragIndex = item.index;

        if (
          (props.data && props.data.isContainer) ||
          item.itemType === ItemTypes.CARD
        ) {
          return;
        }
        if (item.data && typeof item.setAsChild === "function") {
          // console.log('BOX', item);
          if (dragIndex === -1) {
            props.insertCard(item, hoverIndex, item.id);
          }
        }
      },
      hover: (item, monitor) => {
        if (item.itemType === ItemTypes.BOX && item.index === -1) return;

        // Don't replace multi-column component unless both drag & hover are multi-column
        if (props.data?.isContainer && !item.data?.isContainer) return;

        const dragIndex = item.index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }

        if (dragIndex === -1) {
          if (props.data && props.data.isContainer) {
            return;
          }
          // console.log('CARD', item);
          item.index = hoverIndex;
          props.insertCard(item.onCreate(item.data), hoverIndex);
        }

        // Determine rectangle on screen
        const hoverBoundingRect = cardRef.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

        // Determine mouse position
        const clientOffset = monitor.getClientOffset();

        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;

        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%

        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }

        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        // Time to actually perform the action
        props.moveCard(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.

        // if (item.itemType == ItemTypes.BOX) item.cardIndex = hoverIndex;
        // else
        item.index = hoverIndex;
      },

    });

    const opacity = isDragging ? 0 : 1;

    return (
      <div ref={drag(drop(cardRef))}>
        <ComposedComponent seq={seq} {...props} style={{ ...style, opacity }} />
      </div>
    );
  }

  return Card;
}

import React from "react";
import {
    useQuery
} from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { get } from '../stores/requests';

export default function FormsList() {
    const { formId } = useParams();

    const { data: responses, isLoading, isError, error } = useQuery({
        queryKey: ["formsResponses", formId], queryFn: () => get(`/api/forms/${formId}/responses`)
    });

    return (
        <div>
            <h1>Form {formId} Responses List</h1>
            {isLoading && <div>Loading...</div>}
            {isError && <div>Error: {error.message}</div>}
            {responses && (
                <ul>
                    {responses.map((response) => (
                        <li key={response.id}>
                            <Link to={`/forms/${formId}/responses/${response.id}`}>Form {formId}  response {response.id}</Link>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { get } from "../stores/requests";
import { ReactFormGenerator } from "../components/index";

export default function FormPage() {
    const { formId } = useParams();
    const { data: form, isLoading, isError, error } = useQuery({
        queryKey: ["forms", formId],
        queryFn: () => get("/api/forms/" + formId),
        lazy: !formId,
    });

    return (
        <div>
            {isLoading && <div>Loading...</div>}
            {isError && <div>Error: {error.message}</div>}
            {form && (
                <>
                    <h1>Form {form.id}</h1>
                    <ReactFormGenerator
                        download_path=""
                        back_action="/"
                        back_name="Back"
                        answer_data={{}}
                        action_name="Save"
                        form_action={process.env.REACT_APP_API_URL + "/api/forms/" + form.id}
                        form_method="POST"
                        // skip_validations={true}
                        data={form.schema}
                        locale="en"
                    />
                </>
            )}
        </div>
    );
}

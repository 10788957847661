import React, { useMemo } from "react";
import { injectIntl } from "react-intl";
import ToolbarItem from "./toolbar-draggable-item";
import ToolbarGroupItem from "./toolbar-group-item";

import ID from "../UUID";
import store from "../stores/store";
import { groupBy } from "../functions";

function buildItems(items, defaultItems) {
  if (!items) {
    return defaultItems;
  }
  return items.map((x) => {
    let found = defaultItems.find(
      (y) => x.element === y.element && y.key === x.key
    );
    if (!found) {
      found = defaultItems.find(
        (y) => (x.element || x.key) === (y.element || y.key)
      );
    }
    if (found) {
      if (x.inherited !== false) {
        found = { ...found, ...x };
      } else if (x.groupName) {
        found.groupName = x.groupName;
      }
    }
    return found || x;
  });
}

function buildGroupItems(allItems) {
  const items = allItems.filter((x) => !x.groupName);
  const gItems = allItems.filter((x) => !!x.groupName);
  const grouped = groupBy(gItems, (x) => x.groupName);
  const groupKeys = gItems
    .map((x) => x.groupName)
    .filter((v, i, self) => self.indexOf(v) === i);
  return { items, grouped, groupKeys };
}

function _defaultItemOptions(element, intl) {
  switch (element) {
    case "Dropdown":
    case "Checkboxes":
    case "RadioButtons":
      const key = element.toLowerCase();
      return [1, 2, 3].map((i) => ({
        value: `place_holder_option_${i}`,
        text: intl.formatMessage({ id: `place-holder-option-${i}` }),
        key: `${key}_option_${ID.uuid()}`,
      }));
    default:
      return [];
  }
}

function Toolbar({ intl, showDescription, items: _items }) {
  const items = useMemo(
    () => buildItems(_items, _defaultItems(intl)),
    [intl]
  );


  function _defaultItems(intl) {
    return [
      {
        key: "Header",
        name: intl.formatMessage({ id: "header-text" }),
        icon: "fas fa-heading",
        static: true,
        content: intl.formatMessage({ id: "place-holder-text" }),
      },
      {
        key: "name",
        name: "Candidate name",
        element: "TextInput",
        canHaveAnswer: true,
        label: "Name",
        field_name: 'name'
      },
      {
        key: "choices",
        element: "Dropdown",
        canHaveAnswer: true,
        name: 'Choices',
        icon: "far fa-caret-square-down",
        label: intl.formatMessage({ id: "place-holder-label" }),
        field_name: "dropdown_",
        options: fetch('https://jsonplaceholder.typicode.com/posts'),
      },
      {
        key: "Label",
        name: intl.formatMessage({ id: "label" }),
        static: true,
        icon: "fas fa-font",
        content: intl.formatMessage({ id: "place-holder-text" }),
      },
      {
        key: "Paragraph",
        name: intl.formatMessage({ id: "paragraph" }),
        static: true,
        icon: "fas fa-paragraph",
        content: intl.formatMessage({ id: "place-holder-text" }),
      },
      {
        key: "LineBreak",
        name: intl.formatMessage({ id: "line-break" }),
        static: true,
        icon: "fas fa-arrows-alt-h",
      },
      {
        key: "Dropdown",
        canHaveAnswer: true,
        name: intl.formatMessage({ id: "dropdown" }),
        icon: "far fa-caret-square-down",
        label: intl.formatMessage({ id: "place-holder-label" }),
        field_name: "dropdown_",
        options: [],
      },
      {
        key: "Checkboxes",
        canHaveAnswer: true,
        name: intl.formatMessage({ id: "checkboxes" }),
        icon: "far fa-check-square",
        label: intl.formatMessage({ id: "place-holder-label" }),
        field_name: "checkboxes_",
        options: [],
      },
      {
        key: "RadioButtons",
        canHaveAnswer: true,
        name: intl.formatMessage({ id: "multiple-choice" }),
        icon: "far fa-dot-circle",
        label: intl.formatMessage({ id: "place-holder-label" }),
        field_name: "radiobuttons_",
        options: [],
      },
      {
        key: "TextInput",
        canHaveAnswer: true,
        name: intl.formatMessage({ id: "text-input" }),
        label: intl.formatMessage({ id: "place-holder-label" }),
        icon: "fas fa-font",
        field_name: "text_input_",
      },
      {
        key: "EmailInput",
        canHaveAnswer: true,
        name: intl.formatMessage({ id: "email-input" }),
        label: intl.formatMessage({ id: "place-holder-email" }),
        icon: "fas fa-envelope",
        field_name: "email_input_",
      },
      {
        key: "NumberInput",
        canHaveAnswer: true,
        name: intl.formatMessage({ id: "number-input" }),
        label: intl.formatMessage({ id: "place-holder-label" }),
        icon: "fas fa-plus",
        field_name: "number_input_",
      },
      {
        key: "PhoneNumber",
        canHaveAnswer: true,
        name: intl.formatMessage({ id: "phone-input" }),
        label: intl.formatMessage({ id: "place-holder-phone-number" }),
        icon: "fas fa-phone",
        field_name: "phone_input_",
      },
      {
        key: "TextArea",
        canHaveAnswer: true,
        name: intl.formatMessage({ id: "multi-line-input" }),
        label: intl.formatMessage({ id: "place-holder-label" }),
        icon: "fas fa-text-height",
        field_name: "text_area_",
      },
      // {
      //   key: "FieldSet",
      //   canHaveAnswer: false,
      //   name: intl.formatMessage({ id: "fieldset" }),
      //   label: intl.formatMessage({ id: "fieldset" }),
      //   icon: "fas fa-bars",
      //   field_name: "fieldset-element",
      // },
      // {
      //   key: 'TwoColumnRow',
      //   canHaveAnswer: false,
      //   name: intl.formatMessage({ id: 'two-columns-row' }),
      //   label: '',
      //   icon: 'fas fa-columns',
      //   field_name: 'two_col_row_',
      // },
      // {
      //   key: 'ThreeColumnRow',
      //   canHaveAnswer: false,
      //   name: intl.formatMessage({ id: 'three-columns-row' }),
      //   label: '',
      //   icon: 'fas fa-columns',
      //   field_name: 'three_col_row_',
      // },
      // {
      //   key: 'FourColumnRow',
      //   element: 'MultiColumnRow',
      //   canHaveAnswer: false,
      //   name: intl.formatMessage({ id: 'four-columns-row' }),
      //   label: '',
      //   icon: 'fas fa-columns',
      //   field_name: 'four_col_row_',
      //   col_count: 4,
      //   class_name: 'col-md-3',
      // },
      // {
      //   key: 'FiveColumnRow',
      //   element: 'MultiColumnRow',
      //   canHaveAnswer: false,
      //   name: intl.formatMessage({ id: 'five-columns-row' }),
      //   label: '',
      //   icon: 'fas fa-columns',
      //   field_name: 'five_col_row_',
      //   col_count: 5,
      //   class_name: 'col',
      // },
      {
        key: "Image",
        name: intl.formatMessage({ id: "image" }),
        label: "",
        icon: "far fa-image",
        field_name: "image_",
        src: "",
      },
      {
        key: "DatePicker",
        canDefaultToday: true,
        canReadOnly: true,
        dateFormat: "MM/dd/yyyy",
        timeFormat: "hh:mm aa",
        showTimeSelect: false,
        showTimeSelectOnly: false,
        showTimeInput: false,
        name: intl.formatMessage({ id: "date" }),
        icon: "far fa-calendar-alt",
        label: intl.formatMessage({ id: "place-holder-label" }),
        field_name: "date_picker_",
      },
      {
        key: "HyperLink",
        name: intl.formatMessage({ id: "website" }),
        icon: "fas fa-link",
        static: true,
        content: intl.formatMessage({ id: "place-holder-website-link" }),
        href: "http://www.example.com",
      },
      {
        key: "Download",
        name: intl.formatMessage({ id: "file-attachment" }),
        icon: "fas fa-file",
        static: true,
        content: intl.formatMessage({ id: "place-holder-file-name" }),
        field_name: "download_",
        file_path: "",
        _href: "",
      },
      // {
      //   key: 'Range',
      //   name: intl.formatMessage({ id: 'range' }),
      //   icon: 'fas fa-sliders-h',
      //   label: intl.formatMessage({ id: 'place-holder-label' }),
      //   field_name: 'range_',
      //   step: 1,
      //   default_value: 3,
      //   min_value: 1,
      //   max_value: 5,
      //   min_label: intl.formatMessage({ id: 'easy' }),
      //   max_label: intl.formatMessage({ id: 'difficult' }),
      // },
      {
        key: "FileUpload",
        name: intl.formatMessage({ id: "file-upload" }),
        icon: "fas fa-file",
        label: intl.formatMessage({ id: "place-holder-label" }),
        field_name: "file_upload_",
      },
    ];
  }

  function addCustomOptions(item, elementOptions) {
    if (item.type === "custom") {
      const customOptions = Object.assign({}, item, elementOptions);
      customOptions.custom = true;
      customOptions.component = item.component || null;
      customOptions.custom_options = item.custom_options || [];
      return customOptions;
    }
    return elementOptions;
  }

  function create(item) {
    const elementKey = item.element || item.key;
    const elementOptions = addCustomOptions(item, {
      id: ID.uuid(),
      element: elementKey,
      text: item.name,
      groupName: item.groupName,
      static: item.static,
      required: false,
      showDescription: item.showDescription,
    });

    if (showDescription === true && !item.static) {
      elementOptions.showDescription = true;
    }

    if (item.static) {
      elementOptions.bold = false;
      elementOptions.italic = false;
    }

    if (item.canHaveAnswer) {
      elementOptions.canHaveAnswer = item.canHaveAnswer;
    }

    if (item.canReadOnly) {
      elementOptions.readOnly = false;
    }

    if (item.canDefaultToday) {
      elementOptions.defaultToday = false;
    }

    if (item.content) {
      elementOptions.content = item.content;
    }

    if (item.href) {
      elementOptions.href = item.href;
    }

    if (item.inherited !== undefined) {
      elementOptions.inherited = item.inherited;
    }

    elementOptions.canHaveDisplayHorizontal =
      item.canHaveDisplayHorizontal !== false;
    if (elementOptions.canHaveDisplayHorizontal) {
      elementOptions.inline = item.inline;
    }
    elementOptions.canHaveOptionCorrect = item.canHaveOptionCorrect !== false;
    elementOptions.canHaveOptionValue = item.canHaveOptionValue !== false;
    elementOptions.canPopulateFromApi = item.canPopulateFromApi !== false;

    if (item.class_name) {
      elementOptions.class_name = item.class_name;
    }

    if (elementKey === "Image") {
      elementOptions.src = item.src;
    }

    if (elementKey === "DatePicker") {
      elementOptions.dateFormat = item.dateFormat;
      elementOptions.timeFormat = item.timeFormat;
      elementOptions.showTimeSelect = item.showTimeSelect;
      elementOptions.showTimeSelectOnly = item.showTimeSelectOnly;
      elementOptions.showTimeInput = item.showTimeInput;
    }

    if (elementKey === "Download") {
      elementOptions._href = item._href;
      elementOptions.file_path = item.file_path;
    }

    if (elementKey === "Range") {
      elementOptions.step = item.step;
      elementOptions.default_value = item.default_value;
      elementOptions.min_value = item.min_value;
      elementOptions.max_value = item.max_value;
      elementOptions.min_label = item.min_label;
      elementOptions.max_label = item.max_label;
    }

    if (item.element === "MultiColumnRow") {
      elementOptions.col_count = item.col_count;
    }

    if (item.defaultValue) {
      elementOptions.defaultValue = item.defaultValue;
    }

    if (item.field_name) {
      elementOptions.field_name = item.field_name + ID.uuid();
    }

    if (item.label) {
      elementOptions.label = item.label;
    }

    if (item.options) {
      if (item.options.length > 0) {
        elementOptions.options = item.options.map((x) => ({
          ...x,
          key: `custom_option_${ID.uuid()}`,
        }));
      } else {
        elementOptions.options = _defaultItemOptions(
          elementOptions.element,
          intl
        );
      }
    }

    return elementOptions;
  }

  function _onClick(item) {
    store.dispatch("create", create(item));
  }

  const renderItem = (item) => (
    <ToolbarItem
      data={item}
      key={item.key}
      onClick={() => _onClick(item)}
      onCreate={create}
    />
  );

  const { items: itemsList, grouped, groupKeys } = buildGroupItems(items);
  return (
    <div className="col-md-3 react-form-builder-toolbar float-right">
      <h4>{intl.formatMessage({ id: "toolbox" })}</h4>
      <ul>
        {itemsList.map(renderItem)}
        {groupKeys.map((k) => (
          <ToolbarGroupItem
            key={k}
            name={k}
            group={grouped.get(k)}
            renderItem={renderItem}
          />
        ))}
      </ul>
    </div>
  );
}

export default injectIntl(Toolbar);

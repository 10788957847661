import React from 'react';

import { useDrag } from 'react-dnd';
import ItemTypes from './ItemTypes';
import ID from '../UUID';

function ToolbarItem({ data, onClick, onCreate }) {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: {
      id: ID.uuid(),
      index: -1,
      data,
      onCreate,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <li ref={drag} onClick={onClick} style={{ opacity: isDragging ? 0.5 : 1, cursor: 'move' }}>
      <i className={data.icon}></i>
      {data.name}
    </li>
  );
}

export default ToolbarItem;

import React from "react";
import { Link } from "react-router-dom";

export default function Home() {
    return (
        <ul className="d-flex gap-3 p-3 mb-0">
            <li className="btn btn-link">
                <Link to="/forms">Forms</Link>
            </li>
            <li className="btn btn-link">
                <Link to="/forms/new">New Form</Link>
            </li>
        </ul>
    );
}
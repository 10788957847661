import React, { useRef, useState } from 'react';
import { format, parse, parseISO } from 'date-fns';
import ReactDatePicker from 'react-datepicker';
import ComponentHeader from './component-header';
import ComponentLabel from './component-label';

function updateFormat(props, oldFormatMask) {
  const { showTimeSelect, showTimeSelectOnly, showTimeInput } = props.data;
  const dateFormat = showTimeSelect && showTimeSelectOnly ? '' : props.data.dateFormat;
  const timeFormat = (showTimeSelect || showTimeInput) ? props.data.timeFormat : '';
  const formatMask = (`${dateFormat} ${timeFormat}`).trim();
  const updated = formatMask !== oldFormatMask;

  return { updated, formatMask };
}

function updateDateTime(props, formatMask) {
  let value;
  let internalValue;
  const { defaultToday } = props.data;
  if (defaultToday && (props.defaultValue === '' || props.defaultValue === undefined)) {
    value = format(new Date(), formatMask);
    internalValue = new Date();
  } else {
    value = props.defaultValue;

    if (value === '' || value === undefined) {
      internalValue = undefined;
    } else {
      internalValue = parse(value, formatMask, new Date());
    }
  }
  return {
    value,
    internalValue,
    placeholder: formatMask.toLowerCase(),
    defaultToday
  };
}

const DatePicker = (props) => {
  const inputField = useRef(null);
  const { formatMask } = updateFormat(props, null);
  const [state, setState] = useState(updateDateTime(props, formatMask));

  const handleChange = (dt) => {
    let placeholder;
    if (dt && dt.target) {
      placeholder = (dt && dt.target && dt.target.value === '') ? formatMask.toLowerCase() : '';
      const formattedDate = (dt.target.value) ? format(parseISO(dt.target.value), formatMask) : '';
      setState({
        value: formattedDate,
        internalValue: formattedDate,
        placeholder,
      });
    } else {
      setState({
        value: (dt) ? format(dt, formatMask) : '',
        internalValue: dt,
        placeholder,
      });
    }
  };

  const { showTimeSelect, showTimeSelectOnly, showTimeInput } = props.data;
  const inputProps = { type: 'date', className: 'form-control', name: props.data.field_name };

  const readOnly = props.data.readOnly || props.read_only;
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const placeholderText = formatMask.toLowerCase();

  if (props.mutable) {
    inputProps.defaultValue = props.defaultValue;
    inputProps.ref = inputField;
  }

  return (
    <div className='SortableItem rfb-item' style={{ ...props.style }}>
      <ComponentHeader {...props} />
      <div className="form-group">
        <ComponentLabel {...props} />
        <div>
          {readOnly &&
            <input type="text"
              name={inputProps.name}
              ref={inputProps.ref}
              readOnly={readOnly}
              placeholder={state.placeholder}
              value={state.value}
              className="form-control" />
          }
          {iOS && !readOnly &&
            <input type="date"
              name={inputProps.name}
              ref={inputProps.ref}
              onChange={handleChange}
              dateFormat="MM/DD/YYYY"
              value={state.value}
              className="form-control" />
          }
          {!iOS && !readOnly &&
            <ReactDatePicker
              name={inputProps.name}
              ref={inputProps.ref}
              onChange={handleChange}
              selected={state.internalValue}
              todayButton='Today'
              className="form-control"
              isClearable={true}
              showTimeSelect={showTimeSelect}
              showTimeSelectOnly={showTimeSelectOnly}
              showTimeInput={showTimeInput}
              dateFormat={formatMask}
              portalId="root-portal"
              autoComplete="off"
              placeholderText={placeholderText} />
          }
        </div>
      </div>
    </div>
  );
}

export default DatePicker;

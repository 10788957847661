const headers = {
  Accept: "application/json",
  "Content-Type": "application/json; charset=utf-8",
  // OPTIONS: "",
};

const baseUrl = process.env.REACT_APP_API_URL;

export const post = async (url, data) => {
  const res = await fetch(baseUrl + url, {
    method: "POST",
    headers,
    mode: "cors",
    credentials: "include",
    body: JSON.stringify(data),
  });
  return await res.json();
};

export const get = async (url) => {
  const res = await fetch(baseUrl + url, {
    // method: "GET",
    headers,
    mode: "cors",
    credentials: "include",
  });
  const data = await res.json();
  return data;
};

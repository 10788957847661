import React from "react";
import {
    useQuery
} from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { get } from '../stores/requests';
import { ReactFormGenerator } from "../components/index";

export default function FormResponse() {
    const { formId, responseId } = useParams();

    const { data, isLoading, isError, error } = useQuery({
        queryKey: ["formsResponse", formId, responseId], queryFn: () => get(`/api/forms/${formId}/responses/${responseId}`)
    });

    return (
        <div>
            {isLoading && <div>Loading...</div>}
            {isError && <div>Error: {error.message}</div>}
            {data && (
                <>
                    <h1>Form {formId} Response {data.response.id}</h1>
                    <ReactFormGenerator
                        download_path=""
                        back_action="/"
                        back_name="Back"
                        answer_data={data.response.response}
                        action_name="Save"
                        form_action="/"
                        form_method="POST"
                        read_only={true}
                        hide_actions={true}
                        data={data.form.schema}
                        locale="en"
                    />
                </>
            )}
        </div>
    );
}
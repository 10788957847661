/**
  * <Form />
  */

import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { EventEmitter } from 'fbemitter';
import { injectIntl } from 'react-intl';
import FormValidator from './form-validator';
import FormElements from '../form-elements';
import { TwoColumnRow, ThreeColumnRow, MultiColumnRow } from './multi-column';
import { FieldSet } from './fieldset';
import CustomElement from '../form-elements/custom-element';
import Registry from '../stores/registry';

const {
  Image, Checkboxes, Download, FileUpload,
} = FormElements;
const ReactForm = (props) => {
  const formRef = useRef(null);

  const inputs = {};

  function _convert(answers) {
    if (Array.isArray(answers)) {
      const result = {};
      answers.forEach(x => {
        result[x.name] = x.value;
      });
      return result;
    }
    return answers || {};
  }

  const answerData = _convert(props.answer_data);
  const emitter = new EventEmitter();
  function _getDefaultValue(item) {
    return answerData[item.field_name];
  }

  function _optionsDefaultValue(item) {
    const defaultValue = _getDefaultValue(item);
    if (defaultValue) {
      return defaultValue;
    }

    const defaultChecked = [];
    item.options.forEach(option => {
      if (answerData[`option_${option.key}`]) {
        defaultChecked.push(option.key);
      }
    });
    return defaultChecked;
  }

  function _getItemValue(item, ref) {
    let $item = {
      element: item.element,
      value: '',
    };
    if (item.element === 'DatePicker') {
      $item.value = ref.state.value;
    } else if (item.element === 'FileUpload') {
      $item.value = ref.state.fileUpload;
    } else if (ref && ref.inputField && ref.inputField.current) {
      $item = ReactDOM.findDOMNode(ref.inputField.current);
      if ($item && typeof $item.value === 'string') {
        $item.value = $item.value.trim();
      }
    }
    return $item;
  }

  function _isIncorrect(item) {
    let incorrect = false;
    if (item.canHaveAnswer) {
      const ref = inputs[item.field_name];
      if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
        item.options.forEach(option => {
          const $option = ReactDOM.findDOMNode(ref.options[`child_ref_${option.key}`]);
          if ((option.hasOwnProperty('correct') && !$option.checked) || (!option.hasOwnProperty('correct') && $option.checked)) {
            incorrect = true;
          }
        });
      } else {
        const $item = _getItemValue(item, ref);
        if ($item.value.toLowerCase() !== item.correct.trim().toLowerCase()) {
          incorrect = true;
        }
      }
    }
    return incorrect;
  }

  function _isInvalid(item) {
    let invalid = false;
    if (item.required === true) {
      const ref = inputs[item.field_name];
      if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
        let checked_options = 0;
        item.options.forEach(option => {
          const $option = ReactDOM.findDOMNode(ref.options[`child_ref_${option.key}`]);
          if ($option.checked) {
            checked_options += 1;
          }
        });
        if (checked_options < 1) {
          // errors.push(item.label + ' is required!');
          invalid = true;
        }
      } else {
        const $item = _getItemValue(item, ref);
        if ($item.value === undefined || $item.value.length < 1) {
          invalid = true;
        }
      }
    }
    return invalid;
  }

  function _collect(item) {
    const itemData = {
      id: item.id,
      name: item.field_name,
      custom_name: item.custom_name || item.field_name,
    };
    if (!itemData.name) return null;
    const ref = inputs[item.field_name];
    if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
      const checked_options = [];
      item.options.forEach(option => {
        const $option = ReactDOM.findDOMNode(ref.options[`child_ref_${option.key}`]);
        if ($option.checked) {
          checked_options.push(option.key);
        }
      });
      itemData.value = checked_options;
    } else {
      if (!ref) return null;
      itemData.value = _getItemValue(item, ref).value;
    }
    return itemData;
  }

  function _collectFormData(data) {
    const formData = [];
    data.forEach(item => {
      const item_data = _collect(item);
      if (item_data) {
        formData.push(item_data);
      }
    });
    return formData;
  }

  function validateForm() {
    const errors = [];
    const dataItems = props.data;
    const { intl } = props;

    dataItems.forEach(item => {
      if (_isInvalid(item)) {
        errors.push(`${item.label} ${intl.formatMessage({ id: 'message.is-required' })}!`);
      }

      if (item.element === 'EmailInput') {
        const ref = inputs[item.field_name];
        const emailValue = _getItemValue(item, ref).value;
        if (emailValue) {
          const validateEmail = (email) => email.match(
            // eslint-disable-next-line no-useless-escape
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
          const checkEmail = validateEmail(emailValue);
          if (!checkEmail) {
            errors.push(`${item.label} ${intl.formatMessage({ id: 'message.invalid-email' })}`);
          }
        }
      }

      if (item.element === 'PhoneNumber') {
        const ref = inputs[item.field_name];
        const phoneValue = _getItemValue(item, ref).value;
        if (phoneValue) {
          const validatePhone = (phone) => phone.match(
            // eslint-disable-next-line no-useless-escape
            /^[+]?(1\-|1\s|1|\d{3}\-|\d{3}\s|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/g
          );
          const checkPhone = validatePhone(phoneValue);
          if (!checkPhone) {
            errors.push(`${item.label} ${intl.formatMessage({ id: 'message.invalid-phone-number' })}`);
          }
        }
      }

      if (props.validateForCorrectness && _isIncorrect(item)) {
        errors.push(`${item.label} ${intl.formatMessage({ id: 'message.was-answered-incorrectly' })}!`);
      }
    });

    return errors;
  }

  function handleSubmit(e) {
    e.preventDefault();

    let errors = [];
    if (!props.skip_validations) {
      errors = validateForm();
      // Publish errors, if any.
      emitter.emit('formValidation', errors);
    }

    // Only submit if there are no errors.
    if (errors.length < 1) {
      const { onSubmit } = props;
      if (onSubmit) {
        const data = _collectFormData(props.data);
        onSubmit(data);
      } else {
        formRef.current.submit();
      }
    }
  }

  function handleBlur() {
    // Call submit function on blur
    if (props.onBlur) {
      const { onBlur } = props;
      const data = _collectFormData(props.data);
      onBlur(data);
    }
  }

  function handleChange() {
    // Call submit function on change
    if (props.onChange) {
      const { onChange } = props;
      const data = _collectFormData(props.data);
      onChange(data);
    }
  }

  function getDataById(id) {
    const { data } = props;
    return data.find(x => x.id === id);
  }

  function getCustomElement(item) {
    const { intl } = props;

    if (!item.component || typeof item.component !== 'function') {
      item.component = Registry.get(item.key);
      if (!item.component) {
        console.error(`${item.element} ${intl.formatMessage({ id: 'message.was-not-registered' })}`);
      }
    }

    const inputProps = item.forwardRef && {
      handleChange,
      defaultValue: _getDefaultValue(item),
      ref: c => inputs[item.field_name] = c,
    };

    return (
      <CustomElement
        mutable={true}
        read_only={props.read_only}
        key={`form_${item.id}`}
        data={item}
        {...inputProps}
      />
    );
  }

  function getInputElement(item) {
    if (item.custom) {
      return getCustomElement(item);
    }
    const Input = FormElements[item.element];
    return (<Input
      handleChange={handleChange}
      ref={c => inputs[item.field_name] = c}
      mutable={true}
      key={`form_${item.id}`}
      data={item}
      read_only={props.read_only}
      defaultValue={_getDefaultValue(item)} />);
  }

  function getContainerElement(item, Element) {
    const controls = item.childItems.map(x => (x ? getInputElement(getDataById(x)) : <div>&nbsp;</div>));
    return (<Element mutable={true} key={`form_${item.id}`} data={item} controls={controls} />);
  }

  function getSimpleElement(item) {
    const Element = FormElements[item.element];
    return <Element mutable={true} key={`form_${item.id}`} data={item} />;
  }

  const handleRenderSubmit = () => {
    const name = props.action_name || props.actionName;
    const actionName = name || 'Submit';
    const { submitButton = false } = props;

    return submitButton || <input type='submit' className='btn btn-big' value={actionName} />;
  };

  const handleRenderBack = () => {
    const name = props.back_name || props.backName;
    const backName = name || 'Cancel';
    const { backButton = false } = props;

    return backButton || <a href={props.back_action} className='btn btn-default btn-cancel btn-big'>{backName}</a>;
  };

  const dataItems = props.data;

  const items = dataItems.filter(x => !x.parentId).map(item => {
    if (!item) return null;
    switch (item.element) {
      case 'TextInput':
      case 'EmailInput':
      case 'PhoneNumber':
      case 'NumberInput':
      case 'TextArea':
      case 'Dropdown':
      case 'DatePicker':
      case 'RadioButtons':
      case 'Range':
        return getInputElement(item);
      case 'CustomElement':
        return getCustomElement(item);
      // case 'MultiColumnRow':
      //   return getContainerElement(item, MultiColumnRow);
      // case 'ThreeColumnRow':
      //   return getContainerElement(item, ThreeColumnRow);
      // case 'TwoColumnRow':
      //   return getContainerElement(item, TwoColumnRow);
      // case 'FieldSet':
      //   return getContainerElement(item, FieldSet);
      case 'Checkboxes':
        return <Checkboxes ref={c => inputs[item.field_name] = c} read_only={props.read_only} handleChange={handleChange} mutable={true} key={`form_${item.id}`} data={item} defaultValue={_optionsDefaultValue(item)} />;
      case 'Image':
        return <Image ref={c => inputs[item.field_name] = c} handleChange={handleChange} mutable={true} key={`form_${item.id}`} data={item} defaultValue={_getDefaultValue(item)} />;
      case 'Download':
        return <Download download_path={props.download_path} mutable={true} key={`form_${item.id}`} data={item} />;
      case 'FileUpload':
        return (
          <FileUpload
            ref={(c) => (inputs[item.field_name] = c)}
            read_only={props.read_only || item.readOnly}
            mutable={true}
            key={`form_${item.id}`}
            data={item}
            defaultValue={_getDefaultValue(item)}
          />
        );
      default:
        return getSimpleElement(item);
    }
  });


  return (
    <div>
      <FormValidator emitter={emitter} />
      <div className='react-form-builder-form'>
        <form ref={formRef} encType='multipart/form-data' action={props.form_action} onBlur={handleBlur} onChange={handleChange} onSubmit={handleSubmit} method={props.form_method}>
          {props.authenticity_token &&
            <div className='d-none'>
              <input name='utf8' type='hidden' value='&#x2713;' />
              <input name='authenticity_token' type='hidden' value={props.authenticity_token} />
              <input name='task_id' type='hidden' value={props.task_id} />
            </div>
          }
          {items}
          <div className='btn-toolbar'>
            {!props.hide_actions &&
              handleRenderSubmit()
            }
            {!props.hide_actions && props.back_action &&
              handleRenderBack()
            }
          </div>
        </form>
      </div>
    </div>
  );
};

export default injectIntl(ReactForm);
// ReactForm.defaultProps = { validateForCorrectness: false };

/**
  * <HeaderBar />
  */

import React from 'react';
import DragHandle from './component-drag-handle';

export default function HeaderBar({ data, onDestroy, index, setAsChild, editModeOn }) {
  return (
    <div className="toolbar-header">
      <span className="badge badge-secondary">{data.text}</span>
      <div className="toolbar-header-buttons">
        {data.element !== 'LineBreak' &&
          <div className="btn is-isolated" onClick={(e) => editModeOn(data, e)}><i className="is-isolated fas fa-edit"></i></div>
        }
        <div className="btn is-isolated" onClick={onDestroy.bind(this, data)}><i className="is-isolated fas fa-trash"></i></div>

        <DragHandle data={data} index={index} onDestroy={onDestroy} setAsChild={setAsChild} />
      </div>
    </div>
  );
}

import React, { useState, useRef, useEffect, lazy, Suspense } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import store from "../stores/store";
import SortableFormElements from "./sortable-form-elements";
import CustomDragLayer from "../form-elements/component-drag-layer";
const { PlaceHolder } = SortableFormElements;

const FormElementsEdit = lazy(() => import("./form-elements-edit"));

export default function Preview({
  files = [],
  data: _data,
  showCorrectColumn = false,
  url,
  registry,
}) {
  const [data, setData] = useState(_data || []);
  const [editMode, setEditMode] = useState(false);
  const [editElement, setEditElement] = useState(null);
  const seq = useRef(0);
  const { formId } = useParams();

  const editForm = useRef(null);

  function editModeOn(data, e) {
    e.preventDefault();
    e.stopPropagation();
    setEditMode((prev) => !prev);
    if (editMode) {
      setEditElement(null);
    } else {
      setEditElement(data);
    }
  }

  const editModeOff = (e) => {
    if (editForm.current && !editForm.current.contains(e.target)) {
      manualEditModeOff();
    }
  };

  const manualEditModeOff = () => {
    if (editElement?.dirty) {
      editElement.dirty = false;
      updateElement(editElement);
    }
    if (editMode) {
      setEditMode(false);
      setEditElement(null);
    }
  };

  useEffect(() => {
    store.subscribe((state) => setData(state.data));
    document.addEventListener("mousedown", editModeOff);

    return () => document.removeEventListener("mousedown", editModeOff);
  }, []);

  useEffect(() => {
    const loadUrl = formId !== undefined ? `${url}/${formId}` : undefined;

    store.dispatch("load", {
      loadUrl,
      data,
    });
  }, [formId]);

  function updateElement(element) {
    let found = false;
    const newData = [...data];
    for (let i = 0; i < newData.length; i++) {
      if (element.id === newData[i].id) {
        newData[i] = element;
        found = true;
        break;
      }
    }

    if (found) {
      store.dispatch("updateOrder", newData);
      // setSeq((prev) => (prev > 100_000 ? 0 : prev + 1));
      seq.current = seq.current > 100_000 ? 0 : seq.current + 1;

      // setData(newData);
    }
  }

  function _onDestroy(item) {
    if (item.childItems) {
      item.childItems.forEach((x) => {
        const child = getDataById(x);
        if (child) {
          store.dispatch("delete", child);
        }
      });
    }
    store.dispatch("delete", item);
  }

  function getDataById(id) {
    return data.find((x) => x && x.id === id);
  }

  function swapChildren(data, item, child, col) {
    if (child.col !== undefined && item.id !== child.parentId) {
      return false;
    }
    if (
      !(child.col !== undefined && child.col !== col && item.childItems[col])
    ) {
      // No child was assigned yet in both source and target.
      return false;
    }
    const oldId = item.childItems[col];
    const oldItem = getDataById(oldId);
    const oldCol = child.col;
    // eslint-disable-next-line no-param-reassign
    item.childItems[oldCol] = oldId;
    oldItem.col = oldCol;
    // eslint-disable-next-line no-param-reassign
    item.childItems[col] = child.id;
    child.col = col;
    store.dispatch("updateOrder", data);
    return true;
  }

  function setAsChild(item, child, col, isBusy) {
    if (swapChildren(data, item, child, col)) {
      return;
    }
    if (isBusy) {
      return;
    }
    const oldParent = getDataById(child.parentId);
    const oldCol = child.col;
    // eslint-disable-next-line no-param-reassign
    item.childItems[col] = child.id;
    child.col = col;
    // eslint-disable-next-line no-param-reassign
    child.parentId = item.id;
    // eslint-disable-next-line no-param-reassign
    child.parentIndex = data.indexOf(item);
    if (oldParent) {
      oldParent.childItems[oldCol] = null;
    }
    const list = data.filter((x) => x && x.parentId === item.id);
    const toRemove = list.filter((x) => item.childItems.indexOf(x.id) === -1);
    let newData = [...data];
    if (toRemove.length) {
      newData = data.filter((x) => toRemove.indexOf(x) === -1);
    }
    if (!getDataById(child.id)) {
      newData.push(child);
    }
    store.dispatch("updateOrder", newData);
  }

  function removeChild(item, col) {
    const oldId = item.childItems[col];
    const oldItem = getDataById(oldId);
    if (oldItem) {
      const newData = data.filter((x) => x !== oldItem);
      // eslint-disable-next-line no-param-reassign
      item.childItems[col] = null;
      // delete oldItem.parentId;
      seq.current = seq.current > 100_000 ? 0 : seq.current + 1;
      store.dispatch("updateOrder", newData);
    }
  }

  function restoreCard(item, id) {
    const parent = getDataById(item.data.parentId);
    const oldItem = getDataById(id);
    if (parent && oldItem) {
      const newIndex = data.indexOf(oldItem);
      const newData = [...data]; // data.filter(x => x !== oldItem);
      // eslint-disable-next-line no-param-reassign
      parent.childItems[oldItem.col] = null;
      delete oldItem.parentId;
      // eslint-disable-next-line no-param-reassign
      delete item.setAsChild;
      // eslint-disable-next-line no-param-reassign
      delete item.parentIndex;
      // eslint-disable-next-line no-param-reassign
      item.index = newIndex;
      seq.current = seq.current > 100_000 ? 0 : seq.current + 1;
      // setSeq((prev) => (prev > 100_000 ? 0 : prev + 1));
      store.dispatch("updateOrder", newData);
      // setData(newData);
    }
  }

  function insertCard(item, hoverIndex, id) {
    let newData = [...data];
    if (id) {
      restoreCard(item, id);
    } else {
      newData.splice(hoverIndex, 0, item);
      store.dispatch("insertItem", item);
      // store.dispatch("updateOrder", newData);
      saveData(item, hoverIndex, hoverIndex);
      // setData(newData);
    }
  }

  function moveCard(dragIndex, hoverIndex) {
    const dragCard = data[dragIndex];
    saveData(dragCard, dragIndex, hoverIndex);
  }

  // eslint-disable-next-line no-unused-vars
  function cardPlaceHolder(dragIndex, hoverIndex) {
    // Dummy
  }

  function saveData(dragCard, dragIndex, hoverIndex) {
    const newData = [...data];
    newData.splice(dragIndex, 1);
    newData.splice(hoverIndex, 0, dragCard);
    store.dispatch("updateOrder", newData);
  }

  function getElement(item, index) {
    if (item.custom) {
      if (!item.component || typeof item.component !== "function") {
        // eslint-disable-next-line no-param-reassign
        item.component = registry.get(item.key);
      }
    }
    const SortableFormElement = SortableFormElements[item.element];

    if (SortableFormElement === null) {
      return null;
    }

    return (
      <SortableFormElement
        id={item.id}
        seq={seq}
        index={index}
        moveCard={moveCard}
        insertCard={insertCard}
        mutable={false}
        editModeOn={editModeOn}
        isDraggable={true}
        key={item.id}
        sortData={item.id}
        data={item}
        getDataById={getDataById}
        setAsChild={setAsChild}
        removeChild={removeChild}
        _onDestroy={_onDestroy}
      />
    );
  }

  const items = data
    .filter((x) => !!x && !x.parentId)
    .map((item, index) => getElement(item, index));

  return (
    <div
      className={clsx("col-md-9 react-form-builder-preview float-left", {
        "is-editing": editMode,
      })}
    >
      <div className="edit-form" ref={editForm}>
        {editElement !== null && (
          <Suspense fallback={<div>Loading...</div>}>
            <FormElementsEdit
              {...{
                showCorrectColumn,
                files,
                manualEditModeOff,
                data,
                element: editElement,
                updateElement,
              }}
            />
          </Suspense>
        )}
      </div>
      <div className="Sortable">{items}</div>
      <PlaceHolder
        id="form-place-holder"
        show={items.length === 0}
        index={items.length}
        moveCard={cardPlaceHolder}
        insertCard={insertCard}
      />
      <CustomDragLayer />
    </div>
  );
}

// eslint-disable-next-line max-classes-per-file
import { saveAs } from "file-saver";
import React, { useState, useRef } from "react";
// import Select from 'react-select';
// import ReactBootstrapSlider from 'react-bootstrap-slider';

import DatePicker from "./date-picker";
import ComponentHeader from "./component-header";
import ComponentLabel from "./component-label";
import myxss from "./myxss";
import clsx from "clsx";

const Header = (props) => {

  return (
    <div style={{ ...props.style }} className="SortableItem rfb-item">
      <ComponentHeader {...props} />
      <h3
        className={clsx("static", {
          bold: props.data.bold,
          italic: props.data.italic,
        })}
        dangerouslySetInnerHTML={{ __html: myxss.process(props.data.content) }}
      />
    </div>
  );
};

const Paragraph = (props) => {

  return (
    <div style={{ ...props.style }} className="SortableItem rfb-item">
      <ComponentHeader {...props} />
      <p
        className={clsx("static", {
          bold: props.data.bold,
          italic: props.data.italic,
        })}
        dangerouslySetInnerHTML={{ __html: myxss.process(props.data.content) }}
      />
    </div>
  );
};

const Label = (props) => {

  return (
    <div style={{ ...props.style }} className="SortableItem rfb-item">
      <ComponentHeader {...props} />
      <label
        className={clsx("static form-label", {
          bold: props.data.bold,
          italic: props.data.italic,
        })}
        dangerouslySetInnerHTML={{ __html: myxss.process(props.data.content) }}
      />
    </div>
  );
};

const LineBreak = (props) => (
  <div style={{ ...props.style }} className="SortableItem rfb-item">
    <ComponentHeader {...props} />
    <hr />
  </div>
);

const TextInput = (props) => {
  const inputField = useRef(null);

  const inputProps = {};
  inputProps.name = props.data.field_name;
  if (props.mutable) {
    inputProps.defaultValue = props.defaultValue;
    inputProps.ref = inputField;
  }

  if (props.read_only) {
    inputProps.disabled = "disabled";
  }

  return (
    <div style={{ ...props.style }} className="SortableItem rfb-item">
      <ComponentHeader {...props} />
      <div className="form-group">
        <ComponentLabel {...props} />
        <input type="text" className="form-control" {...inputProps} />
      </div>
    </div>
  );
};

const EmailInput = (props) => {
  const inputField = useRef(null);

  const inputProps = { name: props.data.field_name };
  if (props.mutable) {
    inputProps.defaultValue = props.defaultValue;
    inputProps.ref = inputField;
  }

  if (props.read_only) {
    inputProps.disabled = "disabled";
  }

  return (
    <div style={{ ...props.style }} className="SortableItem rfb-item">
      <ComponentHeader {...props} />
      <div className="form-group">
        <ComponentLabel {...props} />
        <input type="text" className="form-control" {...inputProps} />
      </div>
    </div>
  );
};

const PhoneNumber = (props) => {
  const inputField = React.useRef(null);

  const inputProps = { name: props.data.field_name };
  if (props.mutable) {
    inputProps.defaultValue = props.defaultValue;
    inputProps.ref = inputField;
  }

  if (props.read_only) {
    inputProps.disabled = "disabled";
  }

  return (
    <div style={{ ...props.style }} className="SortableItem rfb-item">
      <ComponentHeader {...props} />
      <div className="form-group">
        <ComponentLabel {...props} />
        <input type="tel" className="form-control" {...inputProps} />
      </div>
    </div>
  );
};

const NumberInput = (props) => {
  const inputField = useRef(null);

  const inputProps = { name: props.data.field_name };

  if (props.mutable) {
    inputProps.defaultValue = props.defaultValue;
    inputProps.ref = inputField;
  }

  if (props.read_only) {
    inputProps.disabled = "disabled";
  }

  return (
    <div style={{ ...props.style }} className="SortableItem rfb-item">
      <ComponentHeader {...props} />
      <div className="form-group">
        <ComponentLabel {...props} />
        <input type="number" className="form-control" {...inputProps} />
      </div>
    </div>
  );
};

const TextArea = (props) => {
  const inputField = useRef(null);

  const inputProps = {};
  inputProps.className = "form-control";
  inputProps.name = props.data.field_name;

  if (props.read_only) {
    inputProps.disabled = "disabled";
  }

  if (props.mutable) {
    inputProps.defaultValue = props.defaultValue;
    inputProps.ref = inputField;
  }

  return (
    <div style={{ ...props.style }} className="SortableItem rfb-item">
      <ComponentHeader {...props} />
      <div className="form-group">
        <ComponentLabel {...props} />
        <textarea {...inputProps} />
      </div>
    </div>
  );
};

const Dropdown = (props) => {
  const inputField = useRef(null);

  const inputProps = {};
  inputProps.name = props.data.field_name;

  if (props.mutable) {
    inputProps.defaultValue = props.defaultValue;
    inputProps.ref = inputField;
  }

  if (props.read_only) {
    inputProps.disabled = "disabled";
  }

  return (
    <div style={{ ...props.style }} className="SortableItem rfb-item">
      <ComponentHeader {...props} />
      <div className="form-group">
        <ComponentLabel {...props} />
        <select className="form-control" {...inputProps}>
          {props.data.options.map((option) => (
            <option value={option.value} key={`preview_${option.key}`}>
              {option.text}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

const Checkboxes = (props) => {
  const options = {};

  return (
    <div style={{ ...props.style }} className="SortableItem rfb-item">
      <ComponentHeader {...props} />
      <div className="form-group">
        <ComponentLabel {...props} />
        {props.data.options.map((option) => {
          const key = `preview_${option.key}`;
          const inputProps = {};
          inputProps.name = `option_${option.key}`;

          inputProps.value = option.value;
          if (props.mutable) {
            inputProps.defaultChecked =
              props.defaultValue !== undefined &&
              props.defaultValue.indexOf(option.key) > -1;
          }
          if (props.read_only) {
            inputProps.disabled = "disabled";
          }
          return (
            <div className={clsx("custom-control custom-checkbox", { "option-inline": props.data.inline })} key={key}>
              <input
                id={`fid_${key}`}
                className="custom-control-input"
                type="checkbox"
                ref={(c) => {
                  if (c && props.mutable) {
                    options[`child_ref_${option.key}`] = c;
                  }
                }}
                {...inputProps}
              />
              <label
                className="custom-control-label"
                htmlFor={`fid_${key}`}
              >
                {option.text}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const RadioButtons = (props) => {
  const options = {};

  return (
    <div style={{ ...props.style }} className="SortableItem rfb-item">
      <ComponentHeader {...props} />
      <div className="form-group">
        <ComponentLabel {...props} />
        {props.data.options.map((option) => {
          const this_key = `preview_${option.key}`;
          const inputProps = {};
          inputProps.name = props.data.field_name;

          inputProps.type = "radio";
          inputProps.value = option.value;
          if (props.mutable) {
            inputProps.defaultChecked =
              props.defaultValue !== undefined &&
              (props.defaultValue.indexOf(option.key) > -1 ||
                props.defaultValue.indexOf(option.value) > -1);
          }
          if (props.read_only) {
            inputProps.disabled = "disabled";
          }

          return (
            <div className={clsx("custom-control custom-radio", { "option-inline": props.data.inline })} key={this_key}>
              <input
                id={`fid_${this_key}`}
                className="custom-control-input"
                ref={(c) => {
                  if (c && props.mutable) {
                    options[`child_ref_${option.key}`] = c;
                  }
                }}
                {...inputProps}
              />
              <label
                className="custom-control-label"
                htmlFor={`fid_${this_key}`}
              >
                {option.text}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Image = (props) => {

  return (
    <div style={props.style} className={clsx("SortableItem rfb-item", { "text-center": props.data.center })}>
      <ComponentHeader {...props} />
      {props.data.src && (
        <img
          src={props.data.src}
          width={props.data.width}
          height={props.data.height}
        />
      )}
      {!props.data.src && <div className="no-image">No Image</div>}
    </div>
  );
};

const HyperLink = (props) => {
  return (
    <div style={{ ...props.style }} className="SortableItem rfb-item">
      <ComponentHeader {...props} />
      <div className="form-group">
        <label className={"form-label"}>
          <a
            target="_blank"
            href={props.data.href}
            dangerouslySetInnerHTML={{
              __html: myxss.process(props.data.content),
            }}
          />
        </label>
      </div>
    </div>
  );
};

const Download = (props) => (
  <div style={{ ...props.style }} className="SortableItem rfb-item">
    <ComponentHeader {...props} />
    <div className="form-group">
      <a href={`${props.download_path}?id=${props.data.file_path}`}>
        {props.data.content}
      </a>
    </div>
  </div>
);

const FileUpload = (props) => {
  const [fileUpload, setFileUpload] = useState(null);

  const displayFileUpload = (e) => {
    const target = e.target;
    let file;

    if (target.files && target.files.length > 0) {
      file = target.files[0];

      setFileUpload(file);
    }
  };

  const clearFileUpload = () => setFileUpload(null);

  const saveFile = async (e) => {
    e.preventDefault();
    const sourceUrl = props.defaultValue;
    const response = await fetch(sourceUrl, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      responseType: "blob",
    });
    const dispositionHeader = response.headers.get("Content-Disposition");
    const resBlob = await response.blob();
    // eslint-disable-next-line no-undef
    const blob = new Blob([resBlob], {
      type: props.data.fileType || response.headers.get("Content-Type"),
    });
    if (dispositionHeader && dispositionHeader.indexOf(";filename=") > -1) {
      const fileName = dispositionHeader.split(";filename=")[1];
      saveAs(blob, fileName);
    } else {
      const fileName = sourceUrl.substring(sourceUrl.lastIndexOf("/") + 1);
      saveAs(response.url, fileName);
    }
  };

  const name = props.data.field_name;
  const fileInputStyle = fileUpload ? { display: "none" } : null;

  return (
    <div style={{ ...props.style }} className="SortableItem rfb-item">
      <ComponentHeader {...props} />
      <div className="form-group">
        <ComponentLabel {...props} />
        {props.read_only === true &&
          props.defaultValue &&
          props.defaultValue.length > 0 ? (
          <div>
            <button className="btn btn-default" onClick={saveFile}>
              <i className="fas fa-download"></i> Download File
            </button>
          </div>
        ) : (
          <div className="image-upload-container">
            <div style={fileInputStyle}>
              <input
                name={name}
                type="file"
                accept={props.data.fileType || "*"}
                className="image-upload"
                onChange={displayFileUpload}
              />
              <div className="image-upload-control">
                <div className="btn btn-default">
                  <i className="fas fa-file"></i> Upload File
                </div>
                <p>Select a file from your computer or device.</p>
              </div>
            </div>

            {fileUpload && (
              <div>
                <div className="file-upload-preview">
                  <div style={{ display: "inline-block", marginRight: "5px" }}>
                    {`Name: ${fileUpload.name}`}
                  </div>
                  <div style={{ display: "inline-block", marginLeft: "5px" }}>
                    {fileUpload.size.length > 6
                      ? `Size:  ${Math.ceil(
                        fileUpload.size / (1024 * 1024)
                      )} mb`
                      : `Size:  ${Math.ceil(fileUpload.size / 1024)} kb`}
                  </div>
                </div>
                <br />
                <div
                  className="btn btn-file-upload-clear"
                  onClick={clearFileUpload}
                >
                  <i className="fas fa-times"></i> Clear File
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

// const Range = (props) => {
//   const inputField = useRef(null);
//   const [value, setValue] = useState(props.defaultValue !== undefined ? parseInt(props.defaultValue, 10) : parseInt(props.data.default_value, 10));

//   const changeValue = (e) => {
//     const { target } = e;
//     setValue(target.value);
//   };

//   const inputProps = {};
//   const name = props.data.field_name;

//   inputProps.type = 'range';
//   inputProps.list = `tickmarks_${name}`;
//   inputProps.min = props.data.min_value;
//   inputProps.max = props.data.max_value;
//   inputProps.step = props.data.step;

//   inputProps.value = value;
//   inputProps.change = changeValue;

//   if (props.mutable) {
//     inputProps.ref = inputField;
//   }

//   const datalist = [];
//   for (let i = parseInt(inputProps.min, 10); i <= parseInt(inputProps.max, 10); i += parseInt(inputProps.step, 10)) {
//     datalist.push(i);
//   }

//   const oneBig = 100 / (datalist.length - 1);

//   const _datalist = datalist.map((d, idx) => <option key={`${inputProps.list}_${idx}`}>{d}</option>);

//   const visible_marks = datalist.map((d, idx) => {
//     const option_props = {};
//     let w = oneBig;
//     if (idx === 0 || idx === datalist.length - 1) { w = oneBig / 2; }
//     option_props.key = `${inputProps.list}_label_${idx}`;
//     option_props.style = { width: `${w}%` };
//     if (idx === datalist.length - 1) { option_props.style = { width: `${w}%`, textAlign: 'right' }; }
//     return <label {...option_props}>{d}</label>;
//   });

//   if (props.read_only) {
//     inputProps.disabled = 'disabled';
//   }

//   return (
//     <div style={{ ...props.style }} className='SortableItem rfb-item'>
//       <ComponentHeader {...props} />
//       <div className="form-group">
//         <ComponentLabel {...props} />
//         <div className="range">
//           <div className="clearfix">
//             <span className="float-left">{props.data.min_label}</span>
//             <span className="float-right">{props.data.max_label}</span>
//           </div>
//           <ReactBootstrapSlider {...inputProps} />
//         </div>
//         <div className="visible_marks">
//           {visible_marks}
//         </div>
//         <input name={name} value={value} type="hidden" />
//         <datalist id={inputProps.list}>
//           {_datalist}
//         </datalist>
//       </div>
//     </div>
//   );
// };

const Range = () => null;
export default {
  Header,
  Paragraph,
  Label,
  LineBreak,
  TextInput,
  EmailInput,
  PhoneNumber,
  NumberInput,
  TextArea,
  Dropdown,
  Checkboxes,
  DatePicker,
  RadioButtons,
  Image,
  HyperLink,
  Download,
  FileUpload,
  Range,
};

import React from 'react';
import HeaderBar from './header-bar';

const ComponentHeader = ({ isFieldSet, editModeOn, _onDestroy, onEdit, data, mutable, setAsChild, index }) => {
  if (mutable) {
    return null;
  }
  return (
    <div>
      <HeaderBar isFieldSet={isFieldSet} editModeOn={editModeOn} data={data} index={index} setAsChild={setAsChild} onDestroy={_onDestroy} onEdit={onEdit} static={data.static} required={data.required} />
    </div>
  );
};

export default ComponentHeader;

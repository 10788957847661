import React from 'react';
import myxss from './myxss';


const ComponentLabel = ({ data, className = 'form-label', read_only: readOnly }) => {
  const hasRequiredLabel = (data.hasOwnProperty('required') && data.required === true && !readOnly);
  const labelText = myxss.process(data.label);
  if (!labelText) {
    return null;
  }
  return (
    <label className={className}>
      <span dangerouslySetInnerHTML={{ __html: labelText }} />
      {hasRequiredLabel && <span className="label-required badge badge-danger">Required</span>}
    </label>
  );
};

export default ComponentLabel;

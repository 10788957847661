/**
 * <ReactFormBuilder />
 */

import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { IntlProvider } from "react-intl";
import Preview from "./preview";
import Toolbar from "./toolbar";
import FormGenerator from "./form";
import store from "../stores/store";
import Registry from "../stores/registry";
import AppLocale from "../language-provider";

const ReactFormBuilder = ({
  data,
  url,
  showCorrectColumn,
  show_description,
  locale,
  toolbarItems,
  files,
  customToolbarItems,
}) => {

  const toolbarProps = {
    showDescription: show_description,
  };

  const language = locale || "en";
  const currentAppLocale = AppLocale[language];
  if (toolbarItems) {
    toolbarProps.items = toolbarItems;
  }
  return (
    <DndProvider backend={HTML5Backend}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <div>
          <div className="react-form-builder">
            <div>
              <Preview
                files={files}
                data={data}
                showCorrectColumn={showCorrectColumn}
                url={url}
                registry={Registry}
              />
              <Toolbar {...toolbarProps} customItems={customToolbarItems} />
            </div>
          </div>
        </div>
      </IntlProvider>
    </DndProvider>
  );
};

function ReactFormGenerator(props) {
  const language = props.locale || "en";
  const currentAppLocale = AppLocale[language];
  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <FormGenerator {...props} />
    </IntlProvider>
  );
}

export {
  ReactFormBuilder,
  ReactFormGenerator,
  store as ElementStore,
  Registry,
};

import React, { useState } from 'react';
import clsx from 'clsx';

function ToolbarGroupItem({ name, group, renderItem }) {

  const [show, setShow] = useState(false);

  return (
    <li>
      <div className="toolbar-group-item">
        <button className="btn btn-link btn-block text-left" type="button" onClick={() => setShow(prev => !prev)}>
          {name}
        </button>
        <div className={clsx('collapse', { "show": show })}>
          <ul>
            {group.map(renderItem)}
          </ul>
        </div>
      </div>
    </li>
  );
}

export default ToolbarGroupItem;

import Store from "beedle";
import { get } from "./requests";

const store = new Store({
  actions: {
    setData(context, data) {
      context.commit("setData", [...data]);
    },

    load(context, { loadUrl, data }) {
      if (loadUrl) {
        get(loadUrl).then((x) => {
          if (data?.length > 0 && x.length === 0) {
            x = [...x, ...data];
          }
          this.setData(context, x);
        });
      } else {
        this.setData(context, data);
      }
    },

    create(context, element) {
      const { data } = context.state;
      data.push(element);
      this.setData(context, data);
    },

    delete(context, element) {
      const { data } = context.state;
      data.splice(data.indexOf(element), 1);
      this.setData(context, data);
    },

    deleteLastItem(context) {
      const { lastItem } = context.state;
      if (lastItem) {
        this.delete(context, lastItem);
        context.commit("setLastItem", null);
      }
    },

    resetLastItem(context) {
      const { lastItem } = context.state;
      if (lastItem) {
        context.commit("setLastItem", null);
      }
    },

    updateOrder(context, elements) {
      const newData = elements.filter((x) => x && !x.parentId);
      elements.filter((x) => x && x.parentId).forEach((x) => newData.push(x));
      this.setData(context, newData);
    },

    insertItem(context, item) {
      context.commit("setLastItem", item.isContainer ? null : item);
    },
  },

  mutations: {
    setData(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.data = payload;
      return state;
    },
    setLastItem(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.lastItem = payload;
      return state;
    },
  },

  initialState: {
    data: [],
    lastItem: null,
  },
});

export default store;

import React, { useState, useEffect } from "react";
import store from "./stores/store";
import { ReactFormGenerator } from "./components/index";
import { useMutation } from "@tanstack/react-query";
import clsx from "clsx";
import { post } from "./stores/requests";

const answers = {};

export default function Demobar() {
  const [data, setData] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [roPreviewVisible, setRoPreviewVisible] = useState(false);

  useEffect(() => {
    store.subscribe((state) => setData(state.data));
  }, []);

  function showPreview() {
    saveFormData();
    setPreviewVisible(true);
  }

  function showRoPreview() {
    saveFormData();
    setRoPreviewVisible(true);
  }

  function closePreview() {
    setPreviewVisible(false);
    setRoPreviewVisible(false);
  }

  const { mutate } = useMutation({
    mutationFn: (data) => post("/api/formdata", data),
    onSuccess: () => console.log("form saved"),
  });
  async function saveFormData() {
    mutate(data);
  }

  return (
    <div className="d-flex gap-1 p-2 mx-2" style={{ width: "70%" }}>
      <h4>Preview</h4>
      <button className="btn btn-primary" onClick={showPreview}>
        Preview Form
      </button>
      <button className="btn btn-default" onClick={showRoPreview}>
        Read Only Form
      </button>
      <button className="btn btn-default" onClick={saveFormData}>
        Save Form
      </button>

      {previewVisible && (
        <div
          className={clsx("modal", { "show d-block": previewVisible })}
          role="dialog"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <ReactFormGenerator
                download_path=""
                back_action="/"
                back_name="Back"
                answer_data={answers}
                action_name="Save"
                form_action="/api/forms"
                form_method="POST"
                // skip_validations={true}
                data={data}
                locale="en"
              />

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={closePreview}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {roPreviewVisible && (
        <div
          className={clsx("modal ro-modal", {
            "show d-block": roPreviewVisible,
          })}
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <ReactFormGenerator
                download_path=""
                back_action="/"
                back_name="Back"
                answer_data={answers}
                action_name="Save"
                form_action="/"
                form_method="POST"
                read_only={true}
                hide_actions={true}
                data={data}
                locale="en"
              />

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default"
                  data-dismiss="modal"
                  onClick={closePreview}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

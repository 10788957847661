import React, { useRef } from 'react';
import ComponentHeader from './component-header';
import ComponentLabel from './component-label';

const CustomElement = (props) => {
  const inputField = useRef(null);

  const { bare } = props.data;
  const inputProps = { name: props.data.field_name, defaultValue: props.defaultValue };

  if (props.mutable && props.data.forwardRef) {
    inputProps.ref = inputField;
  }

  if (props.read_only) {
    inputProps.disabled = 'disabled';
  }

  // Return if component is invalid.
  if (!props.data.component) return null;
  const Element = props.data.component;

  return (
    <div className={'SortableItem rfb-item'} style={{ ...props.style }}>
      <ComponentHeader {...props} />
      {bare ?
        <Element data={props.data} {...props.data.props} {...inputProps} /> :
        <div className="form-group">
          <ComponentLabel className="form-label" {...props} />
          <Element data={props.data} {...props.data.props} {...inputProps} />
        </div>
      }
    </div>
  );
}

export default CustomElement;

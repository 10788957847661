import React from 'react';
import clsx from 'clsx';
import { injectIntl } from 'react-intl';

const PlaceHolder = ({ intl, show = false, text = 'Dropzone' }) => {
  // eslint-disable-next-line no-nested-ternary
  const placeHolder = show ?
    (text === 'Dropzone' ? intl.formatMessage({ id: 'drop-zone' }) : text) : '';

  return (
    <div className={clsx(show ? 'form-place-holder' : 'form-place-holder-hidden')} >
      <div>{placeHolder}</div>
    </div>
  );
}

export default injectIntl(PlaceHolder);

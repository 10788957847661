import enLang from "./entries/en-us";

export const AppLanguages = [
  {
    languageId: "english",
    locale: "en",
    name: "English",
    icon: "us",
  },
];

const AppLocale = {
  en: enLang,
};

export default AppLocale;

import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import DemoBar from "./demobar";
// eslint-disable-next-line no-unused-vars
import { ReactFormBuilder } from "./components/index";

import "./scss/application.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Create a client

import FormsList from "./pages/forms-list";
import FormPage from "./pages/form-page";
import Home from "./pages/home";
import FormResponses from "./pages/form-responses";
import FormResponse from "./pages/form-response";

const queryClient = new QueryClient();
const url = "/api/forms";

const Demo = () => (
  <>
    <DemoBar />
    <ReactFormBuilder url={url} locale="en" />
  </>
);

const Index = () => {
  return (
    <Routes>
      <Route path="/forms" element={<FormsList />} />
      <Route path="/forms/:formId" element={<FormPage />} />
      <Route path="/forms/new" element={<Demo />} />
      {/* <Route path="/" element={<Home />} /> */}
      <Route
        path="/forms/:formId/responses/:responseId"
        element={<FormResponse />}
      />
      <Route path="/forms/:formId/responses" element={<FormResponses />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
const App = () => (
  <>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <>
          <Home />
          <Index />
        </>
      </QueryClientProvider>
    </BrowserRouter>
  </>
);

export default App;

import React, { useEffect, memo } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import ItemTypes from '../components/ItemTypes';

const style = {
  cursor: 'move'
};

// const DragHandle = memo(({ connectDragPreview, connectDragSource }) => {
//   useEffect(() => {
//     if (connectDragPreview) {
//       // Use empty image as a drag preview so browsers don't draw it
//       // and we can draw whatever we want on the custom drag layer instead.
//       connectDragPreview(getEmptyImage(), {
//         // IE fallback: specify that we'd rather screenshot the node
//         // when it already knows it's being dragged so we can hide it with CSS.
//         captureDraggingState: true,
//       });
//     }
//   }, []);

//   return connectDragSource(<div className="btn is-isolated" style={style} ><i className="is-isolated fas fa-grip-vertical"></i></div>);
// });

const DragHandle = memo(({ data, index, onDestroy, setAsChild, getDataById }) => {
  const [{ isDragging }, drag, connectDragPreview] = useDrag({
    type: ItemTypes.BOX,
    item: {
      itemType: ItemTypes.BOX,
      index: data.parentId ? -1 : index,
      parentIndex: data.parentIndex,
      id: data.id,
      col: data.col,
      onDestroy,
      setAsChild,
      getDataById,
      data,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  useEffect(() => {
    if (connectDragPreview) {
      connectDragPreview(getEmptyImage(), {
        captureDraggingState: true,
      });
    }
  }, [connectDragPreview]);

  return (
    <div ref={drag} className="btn is-isolated" style={{ ...style, opacity: isDragging ? 0 : 1 }}>
      <i className="is-isolated fas fa-grip-vertical"></i>
    </div>
  );
});

export default DragHandle;

// export default DragSource(
//   ItemTypes.BOX,
//   dragHandleSource,
//   (connect, monitor) => ({
//     connectDragSource: connect.dragSource(),
//     connectDragPreview: connect.dragPreview(),
//     isDragging: monitor.isDragging(),
//   }),
// )(DragHandle);

import React from "react";
import {
    useQuery
} from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { get } from '../stores/requests';

export default function FormsList() {

    const { data, isLoading, isError, error } = useQuery({
        queryKey: ["forms"], queryFn: () => get("/api/forms")
    });

    return (
        <div>
            <h1>Forms List</h1>
            {isLoading && <div>Loading...</div>}
            {isError && <div>Error: {error.message}</div>}
            {data && (
                <ul>
                    {data.map((form) => (
                        <li key={form.id}>
                            <Link to={`/forms/${form.id}`}>Form {form.id}</Link>
                            {" "}
                            <Link to={`/forms/${form.id}/responses`}>Responses</Link>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}